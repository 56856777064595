import { useContext } from 'react'

import { SanityBlogPostWithoutBody } from '@data/sanity/queries/types/blog'
import {
  SanityProductFragment,
  SanityProductVariantFragment,
} from '@data/sanity/queries/types/product'
import { SanityCart } from '@data/sanity/queries/types/site'
import { PageHeroContext } from '@lib/page-hero-context'
import { PageModuleContext } from '@lib/page-module-context'
import { Reviews } from '@lib/review'

import Module from './module'

interface ModulesProps {
  product?: SanityProductFragment
  posts?: SanityBlogPostWithoutBody[]
  activeVariant?: SanityProductVariantFragment
  onVariantChange?: (variantId: number) => void
  collectionProducts?: SanityProductFragment[]
  featuredProductIds?: number[]
  cartSettings?: SanityCart
  reviews?: Reviews
}

const Modules = ({
  reviews,
  product,
  posts,
  activeVariant,
  onVariantChange,
  collectionProducts,
  featuredProductIds,
  cartSettings,
}: ModulesProps) => {
  const { heroModule } = useContext(PageHeroContext)
  const { casePageModules, pageModules } = useContext(PageModuleContext)

  const activePageModules = casePageModules ?? pageModules

  if (!heroModule && activePageModules.length === 0) {
    return null
  }

  return (
    <>
      {!!heroModule && <Module module={heroModule} />}
      {activePageModules.map((module) => (
        <Module
          key={module._key}
          module={module}
          cartSettings={cartSettings}
          posts={posts}
          collectionProducts={collectionProducts}
          featuredProductIds={featuredProductIds}
          product={product}
          activeVariant={activeVariant}
          onVariantChange={onVariantChange}
          reviews={reviews}
        />
      ))}
    </>
  )
}

export default Modules
